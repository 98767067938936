import styled from 'styled-components' 

export const BannerImage = styled.div.attrs({ 
})`           

    // position: relative;
    // min-height: 720px;
    // width: 100%;
    // background: url(/images/banner_topo.jpg) no-repeat top +35% right +30% / cover;
    position: relative;
    min-height: 720px;
    width: 100%;
    // background: url(/images/banner_topo_GIFanim_02.gif) no-repeat top +35% right +30% / cover;
    background: url(/images/banner_topo.jpg) no-repeat top / cover;
    
`; 

export const BannerOut = styled.div.attrs({ 
})`            
    // min-height: 720px;
    // width: 100%;

    // background: var(--white-color); 
    // mix-blend-mode: multiply;
    min-height: 720px;
    width: 100%;

    background: var(--white-color); 
    mix-blend-mode: multiply;
`; 
// este código comentado estava acima do filter da função abaixo:
    //background: var(--primary-color) url(/images/banner_topo.jpg) no-repeat top +35% right +30% / cover;
    //background: -moz-linear-gradient(90deg, var(--darkprimary-color) 5%, rgba(41,28,68,.7) 90%);
    //background: -webkit-linear-gradient(90deg, var(--darkprimary-color) 5%, rgba(41,28,68,.7) 90%);
    //background: linear-gradient(90deg, var(--darkprimary-color) 5%, rgba(41,28,68,.7) 90%);

// esta linha estava no centro do comando abaixo: filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="var(--darkprimary-color)",endColorstr="rgba(41,28,68,.4)",GradientType=1);
// antes o min-height estava 105vh. Eu alterei todos para 720px        
export const BannerContent = styled.div.attrs({ 
})`           
    min-height: 105vh;
    width: 100%; 

    

    position: absolute;
    top: 0;
    left: 0;
`; 

export const BannerCard = styled.div.attrs({ 
})`        
    max-width: 325px;
    margin: 0 auto;
    
`; 




export const BannerCenterContent = styled.div.attrs({ 
})`            
    display: flex;
    flex-direction: column;
    align-items: center;
`; 

export const BannerCenterActions = styled.div.attrs({ 
})`            
    margin: 10px auto 10px;
    flex:1;
    width: 210px;
`; 



export const BannerLogo = styled.img.attrs({ 
    src:'/icons/logo_lancamento.svg'
})`           
    // margin: 65px auto 20px;
    // margin: -45px auto 10px;
    margin: 0 0 0 auto 20px;
    width: 100%;
    max-width: 210px;

    `; 





export const BannerTitle = styled.h1.attrs({ 
})`       
    font-size: 18px;
    color: var(--darkprimary-color);    

    text-align: center;

`; 
export const BannerSubtitle = styled.div.attrs({ 
})`           
    font-size: 28px;
    line-height: 1.2em;
    color: var(--darkprimary-color);    

    text-align: center; 
    margin: 20px auto;
    max-width: 340px;
`; 
export const BannerText = styled.div.attrs({ 
})`           
    font-size: 18px;
    line-height: 1.2em;
    color: var(--darkprimary-color);    

    text-align: center;
`; 
export const BannerText2 = styled.div.attrs({ 
})`           
    font-size: 18px;
    line-height: 1.2em;
    color: var(--darkprimary-color);    

    text-align: center;
`;
export const BannerSubtext = styled.div.attrs({ 
})`           
    font-size: 14px;
    line-height: 1.2em;
    color: var(--darkprimary-color);    
    text-align: center;
    margin: 0 auto;
    max-width: 340px;
`; 

export const ColorLink = styled.div.attrs({ 
})`           
    color: var(--secondary-color);
    text-decoration: underline;  
`; 

export const LoginContent = styled.div.attrs({ 
})`           
    margin: 10px auto 10px;
    
    width: 210px;
    
`; 