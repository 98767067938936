import React from "react"; 

import { Row, Col, Container } from 'reactstrap'; 
  
import {
    CommomContainer, 
    CommomTitle, 
    CommomText,
    CommomImageBanner,
    CommomCentred
} from './styled'

export default function OnMobile(){  

    return ( 
        <> 
            <CommomContainer>  
                <Container> 
                    <Row>
                        <Col md={{size:6}}> 
                            <CommomCentred spaced>
                                <CommomTitle>TIQ - Livros Digitais Animados</CommomTitle>
                                <CommomText>Uma área no App com <b>livros digitais inéditos</b> com ilustrações <b>animadas</b> para você ler e <b>criar memórias especiais</b> no coração dos seus <b>pequeninos</b></CommomText>
                                <CommomText>*Disponível para smartphones Android e iOS</CommomText>
                            </CommomCentred>
                        </Col>
                        <Col md={{size:6}}> 
                            <CommomCentred>
                                <CommomImageBanner />
                            </CommomCentred>
                        </Col>
                    </Row>
                </Container>
            </CommomContainer>
        </>
    );
}