import React from "react"; 

import { Row, Col, Container } from 'reactstrap'; 
  
import {
    CommomContainer, 
    CommomTitle, 
    CommomText,
    CommomImageBanner,
    CommomCentred
} from './styled'

export default function OnGestantes(){  

    return ( 
        <> 
            <CommomContainer>  
                <Container> 
                    <Row>
                        <Col md={{size:6}}> 
                            <CommomCentred spaced>
                                <CommomTitle>Para as Gestantes</CommomTitle>
                                <CommomText>Uma área com <b>canções</b> para levar a paz que excede todo entendimento e proporcionar um momento de <b>conexão</b> entre <b>mamãe e bebê</b></CommomText>
                            </CommomCentred>
                        </Col>
                        <Col md={{size:6}}> 
                            <CommomCentred>
                                <CommomImageBanner />
                            </CommomCentred>
                        </Col>
                    </Row>
                </Container>
            </CommomContainer>
        </>
    );
}