import React from "react"; 

import { Row, Col, Container } from 'reactstrap'; 
  

import { DefaultUi, Player, Youtube } from "@vime/react";
import {
    CommomContainer, 
    CommomTitle, 
    CommomText,
    CommomImageBanner,
    CommomCentred
} from './styled'

export default function OnTV(){  

    return ( 
        <> 
            <CommomContainer>  
                <Container> 
                    <Row>
                        <Col md={{size:6}}> 
                            <CommomCentred spaced>
                                <CommomTitle>O melhor app para assistir seus desenhos animados com segurança</CommomTitle>
                                <CommomText>Temos <b>mais de 500 desenhos</b> animados licenciados e também vídeos produzidos pelo nosso estúdio, apenas de <b>conteúdos cristãos</b>. <b>Diversão com segurança</b> e sem publicidade</CommomText>
                            </CommomCentred>
                        </Col>
                        <Col md={{size:6}}> 
                            <CommomCentred>
                                <CommomImageBanner >
                                    <div >
                                        <Player>
                                            {/* <Youtube videoId={`dUxUDhSRd8M`} key={`dUxUDhSRd8M`}/> */}
                                            <Youtube videoId={`nGH3aqrny-4`} key={`nGH3aqrny-4`}/>
                                            <DefaultUi />
                                        </Player>
                                    </div>
                                </CommomImageBanner>
                            </CommomCentred>
                        </Col>
                    </Row>
                </Container>
            </CommomContainer>
        </>
    );
}
//<Player autoplay={true}>  na linha 32