import React from "react"; 

import { Row, Col, Container } from 'reactstrap'; 
  
import {
    CommomContainer, 
    CommomTitle, 
    CommomText,
    CommomImageBanner,
    CommomCentred
} from './styled'

export default function OnRodape(){  

    return ( 
        <> 
            <CommomContainer>  
                <Container> 
                    <Row>
                        <Col md={{size:6}}> 
                            <CommomCentred spaced>
                                <CommomTitle>Fale conosco</CommomTitle>
                                <CommomText>WhatsApp +55 92 98216-8732</CommomText>
                                <CommomText>atendimento@joinkids.com.br</CommomText>
                                <CommomText>PIX para doações: 31.570.972/0001-45</CommomText>
                            </CommomCentred>
                        </Col>
                        <Col md={{size:6}}> 
                            <CommomCentred>
                                <CommomImageBanner />
                            </CommomCentred>
                        </Col>
                    </Row>
                </Container>
            </CommomContainer>
        </>
    );
}