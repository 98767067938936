import styled from 'styled-components' 

import Lottie from 'react-lottie';

export const CommomContainer = styled.div.attrs({ 
})`          
    position: relative;
    padding: 0px 0;
`; 

export const CommomTitle = styled.div.attrs({  
})`          
    font-size: 30px;
    font-weight: bold;
    line-height: 0.7em;
    color: var(--primary-color);
    margin: 0;

    width: 100%;
    max-width: 360px;
`;  
 

export const CommomText = styled.div.attrs({  
})`          
    font-size: 18px;
    line-height: 1.5em;
    color: var(--darkprimary-color);
    margin: 15px 0 0 0;

    width: 100%;
    max-width: 360px;
`;  
 


export const CommomImageBanner = styled.img.attrs({  
    src:'/images/p9_IOs_Android.png'
})`           
    width: 100%;
    max-width: 540px;

    @media(max-width: 767px){
        margin-top: 0px;
    }
`;  
 
export const CommomCentred = styled.div.attrs({ 
})`          
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    ${
        props => props.spaced ? `
            padding-top: 20px;
        ` : ``
    }
`; 