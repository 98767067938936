import React from "react"; 

import { Row, Col, Container } from 'reactstrap'; 
  
import {
    CommomContainer, 
    CommomTitle, 
    CommomText,
    CommomImageBanner,
    CommomCentred
} from './styled'

export default function OnMultiOK(){  

    return ( 
        <> 
            <CommomContainer>  
                <Container> 
                    <Row>
                        <Col md={{size:6}}> 
                            <CommomCentred spaced>
                                <CommomTitle>Conectividade com TV e outras telas</CommomTitle>
                                <CommomText>Disponível em smartphones e tablets <b>Android</b> e <b>iOS</b>, Smart TV <b>Samsung</b> e Smart TV <b>LG</b>, notebooks e outros dispositivos</CommomText>
                                {/* <CommomText>*Em breve também nas Smart TVs LG</CommomText> */}
                            </CommomCentred>
                        </Col>
                        <Col md={{size:6}}> 
                            <CommomCentred>
                                <CommomImageBanner />
                            </CommomCentred>
                        </Col>
                    </Row>
                </Container>
            </CommomContainer>
        </>
    );
}