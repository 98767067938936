import React from "react"; 
 

import ContainerLandpage from "containers/Landpage";

import OnFilmeExclusivo from 'components/Landpage/OnFilmeExclusivo'; 
import Banner from "components/Landpage/Banner";
import OnTV01 from 'components/Landpage/OnTV01';
import OnTV from 'components/Landpage/OnTV';
import OnMobile from "components/Landpage/OnMobile";
import OnMulti from "components/Landpage/OnMulti";
import OnGestantes from "components/Landpage/OnGestantes";
import OnOriginais from "components/Landpage/OnOriginais";
import OnFavoDownload from "components/Landpage/OnFavoDownload";
import OnMultiOK from "components/Landpage/OnMultiOK";
import OnPlanos from "components/Landpage/OnPlanos";
import OnAndroidIOS from "components/Landpage/OnAndroidIOS";
import OnRodape from "components/Landpage/OnRodape";

export default function Landpage(){ 
 
    return ( 
        <ContainerLandpage> 

                <OnFilmeExclusivo /> 
                <Banner />
                <OnTV01 />
                <OnTV />  
                <OnMobile />
                <OnMulti />
                <OnGestantes />
                <OnOriginais />
                <OnFavoDownload />
                <OnMultiOK />
                <OnPlanos />
                <OnAndroidIOS />
                <OnRodape />
 
        </ContainerLandpage>
    );
}