import React from "react"; 

import { Row, Col, Container } from 'reactstrap'; 
  
import {
    CommomContainer, 
    CommomTitle, 
    CommomText,
    CommomImageBanner,
    CommomCentred
} from './styled'

export default function OnFavoDownload(){  

    return ( 
        <> 
            <CommomContainer>  
                <Container> 
                    <Row>
                        <Col md={{size:6}}> 
                            <CommomCentred spaced>
                                <CommomTitle>Conheça nossos Planos</CommomTitle>
                                <CommomText>Plano Maná - Anual - R$ 154,80 à vista</CommomText>
                                <CommomText>Plano Sementinha - Mensal - 19,70/mês</CommomText>
                                <CommomText><a href="https://wa.me/5592982168732?text=Eu%20quero!">
                                        <button>Saiba mais clicando aqui</button></a></CommomText>
                            </CommomCentred>
                        </Col>
                        <Col md={{size:6}}> 
                            <CommomCentred>
                                <CommomImageBanner />
                            </CommomCentred>
                        </Col>
                    </Row>
                </Container>
            </CommomContainer>
        </>
    );
}