import React from "react"; 

import { Row, Col, Container } from 'reactstrap'; 
  

import { DefaultUi, Player, Youtube } from "@vime/react";
import {
    CommomContainer, 
    CommomTitle, 
    CommomText,
    CommomImageBanner,
    CommomCentred
} from './styled'

export default function OnTV01(){  

    return ( 
        <> 
            <CommomContainer>  
                <Container> 
                    <Row>
                        <Col md={{size:6}}> 
                            <CommomCentred spaced>
                                <CommomTitle>Filmes Exclusivos</CommomTitle>
                                <CommomText>Além dos <b>melhores desenhos</b> como: Turma do Cristãozinho, Smilinguido, 3 Palavrinhas, Pequenos Atos, Crianças Diante do Trono, Minha Vida é Uma Viagem, Biguinha, Rebeca Nemer, Tia Cecéu, Fingerprint, Sheepelitos e muitos outros, temos também <b>vários vídeos originais</b> </CommomText>
                            </CommomCentred>
                        </Col>
                        <Col md={{size:6}}> 
                            <CommomCentred>
                                <CommomImageBanner >
                                    <div >
                                        <Player autoplay={true}>
                                            <Youtube videoId={`dUxUDhSRd8M`} key={`dUxUDhSRd8M`}/>
                                            <DefaultUi />
                                        </Player>
                                    </div>
                                </CommomImageBanner>
                            </CommomCentred>
                        </Col>
                    </Row>
                </Container>
            </CommomContainer>
        </>
    );
}

//<Youtube videoId={`dUxUDhSRd8M`} key={`dUxUDhSRd8M`}/>
// na linha 32 <Player autoplay={true}>