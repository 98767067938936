import React from "react"; 

import { Row, Col, Container } from 'reactstrap'; 
import { useHistory } from 'react-router-dom';
  

import { 
    BannerImage,
    BannerOut,
    BannerContent,
    BannerCenterContent,
    BannerLogo,
    BannerCenterActions,
    BannerCard,
    ColorLink,
    BannerTitle,
    BannerSubtitle,
    BannerText,
    BannerText2,
    BannerSubtext,

    LoginContent
} from "./styled"; 

import Button from "components/Button";

export default function Banner(){

    const history = useHistory();
    const navigate = to => history.push(`/${ to }`);

    return ( 
        <> 
            <BannerImage>
                <BannerOut />
                <BannerContent>
                    <Container>
                        <Row>
                            <Col md={{ size: 4 }}></Col> 
                            <Col md={{ size: 4 }}>

                            </Col>
                            <Col md={{ size: 12 }}>
                                <LoginContent>
                                    <Button accent onClick={() => navigate('login')}>Entrar</Button>
                                </LoginContent>
                            </Col>
                        </Row>        
                        <Row>
                            <Col md={{ size: 4 }}></Col>
                            <Col md={{ size: 4 }}> 
                                <BannerCard>
                                    {/* <BannerCenterContent>
                                        <BannerLogo />
                                    </BannerCenterContent> */}
                                    {/* <BannerText>Aqui,&nbsp; Jesus é o nosso principal personagem</BannerText> */}
                                    {/* <BannerText>Vídeos Infantis que Ensinam</BannerText> */}
                                    {/* <BannerTitle><a href="home_joinKids_mobile.html"> Veja nossa galeria de vídeos </a></BannerTitle> */}
                                    <BannerSubtitle><strong>Ambiente 100% Seguro e Sem Anúncios</strong></BannerSubtitle>
                                    {/* <BannerText>Aqui,&nbsp; Jesus é o nosso principal personagem</BannerText> */}
                                    <BannerText2><b>Na JoinKids, você tem:</b></BannerText2>
                                    <BannerSubtext>&nbsp;</BannerSubtext>
                                    <BannerText2>✔Acesso <b>ilimitado</b> a todas as séries, filmes e programas cristãos para crianças</BannerText2>
                                    <BannerSubtext>&nbsp;</BannerSubtext>
                                    <BannerText2>✔Acesso aos <b>livros digitais</b> animados</BannerText2>
                                    <BannerSubtext>&nbsp;</BannerSubtext>
                                    <BannerText2>✔Acesso de até <b>03 crianças</b> simultaneamente</BannerText2>
                                    <BannerSubtext>&nbsp;</BannerSubtext>
                                    <BannerText2>✔Conteúdo <b>educativo</b> e divertido para seus filhos</BannerText2>
                                    <BannerSubtext>&nbsp;</BannerSubtext>
                                    <BannerText2>✔Novos lançamentos <b>exclusivos</b> durante o ano inteiro</BannerText2>

                                    <BannerCenterActions>
                                        <Button primary onClick={() => navigate('planos')}>Assinar Agora</Button>
                                        {/* <Button secondary onClick={() => navigate('register')}>Plano Mensal R$16,90</Button>
                                        <Button accent onClick={() => navigate('register?provider=giftcard')}>Assine com o Cartão Pré-pago</Button> */}
                                        {/* <Button secondary href="https://wa.me/5592982168732?text=Eu%20quero!">Pedir Aqui, 03 Dias Grátis</Button> */}
                                        <BannerTitle><a href="https://wa.me/5592982168732?text=Eu%20quero%20saber%20mais!"><ColorLink><Button secondary>Saiba mais</Button></ColorLink></a></BannerTitle>
                                    </BannerCenterActions>
                                    {/* <BannerTitle><a href="https://wa.me/5592982168732?text=Eu%20quero!">
                                        <button> Peça aqui, 03 dias grátis!</button></a>
                                    </BannerTitle> */}
                                    {/* <BannerSubtext><strong>TESTE POR 7 DIAS GRÁTIS</strong></BannerSubtext> */}
                                    <BannerSubtext>Disponível na Web, celular/tablet (Android e iOS) e Smart TV Samsung e Smart TV LG.</BannerSubtext>
                                    {/* <BannerSubtext>Assine o Plano Anual. Oferta válida até 31/10/23</BannerSubtext> */}
                                </BannerCard>
                            </Col>
                            <Col md={{ size: 4 }}>
                                
                            </Col>
                            
                        </Row>
                    </Container>
                </BannerContent>
            </BannerImage>
        </>
    );
}