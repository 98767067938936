import React from "react"; 

import { Row, Col, Container } from 'reactstrap'; 
  
import {
    CommomContainer, 
    CommomTitle, 
    CommomText,
    CommomImageBanner,
    CommomCentred
} from './styled'

export default function OnPlanos(){  

    return ( 
        <> 
            <CommomContainer>  
                <Container> 
                    <Row>
                        <Col md={{size:6}}> 
                            <CommomCentred spaced>
                                <CommomTitle>Baixe em seu smartphone</CommomTitle>
                                <CommomText>Primeiro, faça sua conta pelo nosso site e depois baixe o app em seu smartphone</CommomText>
                                <CommomText><a href="https://play.google.com/store/apps/details?id=com.joinkids">Clique aqui para Android</a></CommomText>
                                <CommomText><a href="https://apps.apple.com/br/app/joinkids-brasil/id6443968852">Clique aqui para iOS</a></CommomText>
                            </CommomCentred>
                        </Col>
                        <Col md={{size:6}}> 
                            <CommomCentred>
                                <CommomImageBanner />
                            </CommomCentred>
                        </Col>
                    </Row>
                </Container>
            </CommomContainer>
        </>
    );
}