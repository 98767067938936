import React from "react"; 

import { Row, Col, Container } from 'reactstrap';
import { useHistory } from 'react-router-dom'; 
  
import {
    CommomContainer, 
    CommomTitle, 
    CommomText,
    CommomImageBanner,
    CommomCentred,

    BannerCenterActions,
    BannerCard,

    BannerTitle,
    BannerSubtitle,
    BannerText,
    BannerSubtext,
    BannerCenterContent,
    BannerLogo
} from './styled'

import Button from "components/Button";


export default function OnFavoDownload(){ 
    
    const history = useHistory();
    const navigate = to => history.push(`/${ to }`);

    return ( 
        <> 
            <CommomContainer>  
                <Container> 
                    <Row>
                        
                            <Col md={{ size: 6 }}> 
                                <BannerCard>
                                    <BannerCenterContent>
                                        <BannerLogo />
                                    </BannerCenterContent>
                                    {/* <BannerText>Aqui,&nbsp; Jesus é o nosso principal personagem</BannerText> */}
                                    <BannerText>Vídeos Infantis que Ensinam</BannerText>
                                    {/* <BannerTitle><a href="home_joinKids_mobile.html"> Veja nossa galeria de vídeos </a></BannerTitle> */}
                                    <BannerSubtitle><strong>Escolha o seu melhor Plano</strong></BannerSubtitle>
                                    {/* <BannerText>Aqui,&nbsp; Jesus é o nosso principal personagem</BannerText> */}
                                    <BannerText>Até 03 crianças por assinatura</BannerText>
                                    <BannerCenterActions>
                                        <Button primary onClick={() => navigate('register')}>Plano Anual de &nbsp;<del>R$236,40</del>&nbsp; por R$154,80</Button>
                                        <Button secondary onClick={() => navigate('register')}>Plano Mensal R$19,70</Button>
                                        <Button accent onClick={() => navigate('register?provider=giftcard')}>Assinar com o Cartão Pré-pago</Button>
                                    </BannerCenterActions>
                                    {/* <BannerTitle><a href="https://wa.me/5592982168732?text=Eu%20quero!">
                                        <button> Peça aqui, 03 dias grátis!</button></a></BannerTitle> */}
                                    {/* <BannerSubtext><strong>TESTE POR 7 DIAS GRÁTIS</strong></BannerSubtext> */}
                                    <BannerSubtext>Disponível na Web, celular/tablet (Android e iOS) e Smart TV Samsung e Smart TV LG.</BannerSubtext>
                                    {/* <BannerSubtext>Assine o Plano Anual. Oferta válida até 31/10/23</BannerSubtext> */}
                                </BannerCard>
                            </Col>

                        
                        {/* <Col md={{size:6}}> 
                            <CommomCentred spaced>
                                <CommomTitle>Conheça nossos Planos</CommomTitle>
                                <CommomText>Plano Maná - Anual - R$ 139,20 à vista</CommomText>
                                <CommomText>Plano Sementinha - Mensal - 16,90/mês</CommomText>
                                <CommomText><a href="https://wa.me/5592982168732?text=Eu%20quero!">
                                        <button> Experimente! Peça aqui, 03 dias grátis</button></a></CommomText>
                            </CommomCentred>
                        </Col> */}
                        <Col md={{size:6}}> 
                            <CommomCentred>
                                <CommomImageBanner />
                            </CommomCentred>
                        </Col>
                    </Row>
                </Container>
            </CommomContainer>
        </>
    );
}