import React from "react"; 

import { Row, Col, Container } from 'reactstrap'; 
  
import {
    CommomContainer, 
    CommomTitle, 
    CommomText,
    CommomImageBanner,
    CommomCentred
} from './styled'

export default function OnFavoDownload(){  

    return ( 
        <> 
            <CommomContainer>  
                <Container> 
                    <Row>
                        <Col md={{size:6}}> 
                            <CommomCentred spaced>
                                <CommomTitle>Favorite e faça o download</CommomTitle>
                                <CommomText>Os <b>vídeos</b> mais amados podem ficar <b>guardados</b> para serem <b>assistidos</b> pelo smartphone em qualquer lugar, mesmo <b>sem internet</b></CommomText>
                                <CommomText>*Disponível para Android e iOS</CommomText>
                            </CommomCentred>
                        </Col>
                        <Col md={{size:6}}> 
                            <CommomCentred>
                                <CommomImageBanner />
                            </CommomCentred>
                        </Col>
                    </Row>
                </Container>
            </CommomContainer>
        </>
    );
}